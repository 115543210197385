import {React, useEffect} from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components-pl/layout"
import Seo from "../components-pl/seo"
import Logo from "../images/blog.svg"
import Background from "../images/bg.svg"
import Cursor from "../images/smallshroom.svg"
import Bye from "../images/bye.svg"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {


    useEffect(() => {



      if(localStorage.getItem("language")==null){
        localStorage.setItem("language", 'PL')
      }
      
      window.onscroll = function() {
        if(window.scrollY >= 45){
          document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
          document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
          document.querySelector("#gatsby-focus-wrapper > div:nth-child(4) > main > section.hero.is-white.is-medium").style.marginTop = '65px'
        }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
        document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
        document.querySelector("#gatsby-focus-wrapper > div:nth-child(4) > main > section.hero.is-white.is-medium").style.marginTop = '0'
        }
        let mybtn = document.getElementById("back-to-top");
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybtn.style.display = "block";
        } else {
          mybtn.style.display = "none";
        }

      
        };

    })




  const posts = data.allWpPost.nodes
  const cats = data.allWpCategory
  console.log(nextPagePath, previousPagePath )
  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="Wszystkie artykuły | Blog" />

      <section className="hero is-white is-medium" style={{marginTop:'0px',minHeight:'600px'}}>
      {/* Hero */}
        <div className="container-fluid mush" style={{
            backgroundImage: `url(${Background})`,
            minHeight: '600px',
            backgroundSize:'cover',
            backgroundPosition:'bottom',
            padding:'0',
            backgroundColor: '#FCFAF6'
          }}>
          <div className="mask container-fluid" style={{display:'flex',alignItems:'center',flexWrap:'wrap',minHeight: '600px',justifyContent:'center'}}>
          <div className="col-8" style={{minHeight: '400px',display:'block',textAlign:'center'}}>

          <h1 className="text-black mob-hero spec-h" style={{textAlign:'center',fontSize:'5em',wordBreak:'break-all'}}>Witaj na<br /> blogu!</h1>
          <br />
          <h2 style={{textAlign:'center',fontSize:'1.5em'}}>Dowiedz się więcej o oryginalnym napoju<br /> funkcjonalnym na bazie grzybów medycznych.</h2>
          <p style={{textAlign:'center',margin:'auto',maxWidth:'500px',color:'#111'}}>Miłej lektury!
          </p>
            <br />
            <a href="#articles" className="btn btn-outline-primary btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
              Przejrzyj artykuły&nbsp;🍄
            </a>
            <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/pl/" className="btn btn-outline-primary btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
              Zobacz sklep&nbsp;🛒
            </a>

          </div>
          </div>
          <div id="scroll-to" style={{padding:'2em',textAlign:'center',color:'#1D1D1D'}}>
          <a href="#scroll-to" style={{fontSize:'1.5em'}}>⇩</a>
          </div>
        </div>


    </section>



    <div className="content notification has-text-centered is-black hero is-medium" id="articles" style={{padding:'100px 50px',backgroundColor:'#4C365C',borderRadius:'0px'}}>
    <h2 className="title spec-h" style={{color:'#fefefe'}}><span role="img" aria-label="mushroom">✨</span> Przeczytaj nasze artykuły <span role="img" aria-label="mushroom">✨</span></h2>
    <p>Dowiedz się więcej o naszych badaniach oraz produktach.<br />Czytaj artykuły, zobacz aktualne oferty pracy, dowiedz się więcej o naszych działaniach.</p>
      <nav className="tabs">
        <div className="container has-text-centered is-black">
          <ul style={{maxWidth:'90%',marginLeft:'5%',display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
          {cats.nodes.map(post => {
            return (
            <>
            <li><Link to={post.uri} style={{textDecoration:'none'}}>{post.name}</Link></li>
            </>)})}
          </ul>
        </div>
      </nav>
    </div>
    {/*<code>
    Note: Max 600px width for blog images
    </code>*/}
    <section>
      <div id="bottomSection" style={{ marginTop:'50px',display:'flex',flexWrap:'wrap',justifyContent:'center' }} className="columns content">

    {posts.map(post => {
      return (
      <>
      <div className="col-3 card mush" style={{textAlign:'center',margin:'.5em',padding:'15px',minHeight:'700px',boxShadow:'0 4px 30px rgba(0, 0, 0, 0.1)'}}>
      <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',alignItems:'center',margin:'0',padding:'0'}}>
      <Link to={post.categories.nodes[0].uri}>{post.categories.nodes[0].name}</Link>
      <small style={{border:'1px solid #1D1D1D',color:'#1D1D1D',margin:'10px 0px',padding:'0 10px',borderRadius:'3px'}}>{post.date}</small>
      </div>
      <Link href={post.uri}>
      <GatsbyImage
        image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
        alt={post.featuredImage.node.altText ? post.featuredImage.node.altText : post.title}
        style={{ minWidth:'200px',maxWidth:'500px', minHeight:'auto',borderRadius:'20px' }}
        imgStyle={{minWidth:'200px',maxWidth:'500px',minHeight:'auto',borderRadius:'20px', objectFit:'contain'}}
        loading="lazy"
        className="blog-mod-img"
      />
      </Link>
      <p className="spec-h" style={{paddingTop:'15px',fontSize:'1.5em',margin:'auto',textAlign:'left'}}>{post.title}</p>

      <p style={{textAlign:'left',fontSize:'.9em',margin:'auto'}}>{parse(post.excerpt)}</p>
      <Link style={{maxWidth:'300px',margin:'auto'}} className="btn" rel="noreferrer" target="_blank" ariaLabel={post.uri} to={post.uri}>Przeczytaj artykuł &nbsp;<span role="img" aria-label="mushroom">🍄</span></Link>

      <p className="is-small" itemProp="author" style={{marginTop:'20px',textAlign:'left',cursor:'auto'}}> Autor:&nbsp;<b>{parse(post.author.node.name)}</b></p>
      </div>
      </>
      )
    })}
    </div>
    </section>
      <div className="has-text-centered" style={{marginTop:'50px'}}>
            {previousPagePath && (
              <>
                <Link className="btn  btn-outline-dark" to={previousPagePath}>&lt; Poprzednia strona</Link>
                <br />
              </>
            )}

            {nextPagePath && (
              <>
              <br />
              <Link className="btn  btn-outline-dark" to={nextPagePath}>Następna strona &gt;</Link>
              </>
            )}

      </div>

      <br />
      <br />


    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
query WordPressPostArchivePL($offset: Int!, $postsPerPage: Int!) {
allWpCategory(filter: {language: {code: {eq: PL}}}) {
  nodes {
    name
    uri
  }
}
allWpPost(sort: {fields: [date], order: DESC}, limit: $postsPerPage, skip: $offset, filter: {language: {code: {eq: PL}}}) {
  nodes {
    excerpt
    uri
    date(formatString: "DD.MM.YYYY")
    title
    excerpt
    categories {
      nodes {
        name
        uri
      }
    }
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    }
    author {
      node {
        avatar {
          url
        }
        name
      }
    }
    tags {
      nodes {
        name
      }
    }
  }
}
}
`
